.content{
    min-height: calc(100vh - 250px);
    margin: 88px 0px;
    height: auto;
    width: auto;
    padding: 0 50px;
}

@media screen and (max-width: 500px){
    .content {
        padding: 0 6px;
    }
}

.content-in{
    width: auto;
}

.footer{
    text-align: center;
}

.logo {
    float: left;
    width: 250px;
}
.logo b{
    color:#f7f7f7;
    margin-left: 6px;
    font-size: 20px;
}

.ant-tag{
    font-weight: 600;
    font-size: 12px;
}